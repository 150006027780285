import { SVGProps } from "react"
import { JSX } from "react/jsx-runtime"

interface ArrowCounterIconProps
  extends JSX.IntrinsicAttributes,
    SVGProps<SVGSVGElement> {
  className?: string
}

export const BackIcon = ({
  className = "",
  ...props
}: ArrowCounterIconProps) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`${className} arrow-color-icon`}
    {...props}
  >
    <path d="M15.023 5.78953L11.0855 1.85203C11.0332 1.79981 10.9711 1.7584 10.9029 1.73017C10.8346 1.70194 10.7614 1.68744 10.6875 1.6875H3.9375C3.63913 1.6875 3.35298 1.80603 3.142 2.017C2.93103 2.22798 2.8125 2.51413 2.8125 2.8125V15.1875C2.8125 15.4859 2.93103 15.772 3.142 15.983C3.35298 16.194 3.63913 16.3125 3.9375 16.3125H14.0625C14.3609 16.3125 14.647 16.194 14.858 15.983C15.069 15.772 15.1875 15.4859 15.1875 15.1875V6.1875C15.1876 6.11361 15.1731 6.04043 15.1448 5.97215C15.1166 5.90386 15.0752 5.84181 15.023 5.78953ZM11.25 3.60773L13.2673 5.625H11.25V3.60773ZM14.0625 15.1875H3.9375V2.8125H10.125V6.1875C10.125 6.33668 10.1843 6.47976 10.2898 6.58525C10.3952 6.69074 10.5383 6.75 10.6875 6.75H14.0625V15.1875Z" />
    <rect x="6" y="6" width="12" height="12" rx="6" fill="#ECF9FF" />
    <path d="M16 12.0008C16.0001 13.0522 15.6167 14.0614 14.9328 14.8099C14.2489 15.5583 13.3196 15.9859 12.3459 16H12.2963C11.3508 16.0021 10.4407 15.6113 9.75481 14.9086C9.66911 14.8212 9.61907 14.7006 9.6157 14.5734C9.61233 14.4462 9.65591 14.3227 9.73685 14.2301C9.81779 14.1376 9.92946 14.0836 10.0473 14.0799C10.1651 14.0763 10.2795 14.1233 10.3652 14.2107C10.7675 14.6209 11.2728 14.8939 11.818 14.9956C12.3632 15.0974 12.9241 15.0234 13.4306 14.783C13.937 14.5425 14.3666 14.1462 14.6657 13.6435C14.9648 13.1409 15.12 12.5543 15.112 11.9569C15.1041 11.3595 14.9332 10.778 14.6209 10.2848C14.3086 9.79171 13.8686 9.40892 13.3559 9.18429C12.8432 8.95967 12.2806 8.90316 11.7383 9.02185C11.1961 9.14053 10.6982 9.42914 10.307 9.8516C10.3022 9.8568 10.2978 9.8616 10.2926 9.8664L9.58852 10.5611H10.2222C10.3401 10.5611 10.4531 10.6116 10.5365 10.7016C10.6198 10.7916 10.6667 10.9137 10.6667 11.041C10.6667 11.1682 10.6198 11.2903 10.5365 11.3803C10.4531 11.4703 10.3401 11.5209 10.2222 11.5209H8.44444C8.32657 11.5209 8.21352 11.4703 8.13017 11.3803C8.04683 11.2903 8 11.1682 8 11.041V9.12135C8 8.99407 8.04683 8.872 8.13017 8.782C8.21352 8.692 8.32657 8.64144 8.44444 8.64144C8.56232 8.64144 8.67536 8.692 8.75871 8.782C8.84206 8.872 8.88889 8.99407 8.88889 9.12135V9.94998L9.68444 9.16374C10.2033 8.6065 10.8634 8.22772 11.5814 8.0752C12.2995 7.92269 13.0433 8.00327 13.7189 8.30679C14.3946 8.6103 14.9718 9.12315 15.3778 9.78059C15.7838 10.438 16.0003 11.2106 16 12.0008Z" />
  </svg>
)
