import { SVGProps } from "react"
import { JSX } from "react/jsx-runtime"

interface ArrowCounterIconProps
  extends JSX.IntrinsicAttributes,
    SVGProps<SVGSVGElement> {
  className?: string
}

export const ActionsReportIcon = ({
  className = "",
  ...props
}: ArrowCounterIconProps) => (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`${className} arrow-color-icon`}
    {...props}
  >
    <path d="M26.7075 10.7925L19.7075 3.7925C19.6146 3.69967 19.5042 3.62605 19.3829 3.57586C19.2615 3.52568 19.1314 3.4999 19 3.5H7C6.46957 3.5 5.96086 3.71071 5.58579 4.08579C5.21071 4.46086 5 4.96957 5 5.5V27.5C5 28.0304 5.21071 28.5391 5.58579 28.9142C5.96086 29.2893 6.46957 29.5 7 29.5H25C25.5304 29.5 26.0391 29.2893 26.4142 28.9142C26.7893 28.5391 27 28.0304 27 27.5V11.5C27.0001 11.3686 26.9743 11.2385 26.9241 11.1172C26.8739 10.9958 26.8003 10.8854 26.7075 10.7925ZM20 6.91375L23.5863 10.5H20V6.91375ZM25 27.5H7V5.5H18V11.5C18 11.7652 18.1054 12.0196 18.2929 12.2071C18.4804 12.3946 18.7348 12.5 19 12.5H25V27.5ZM21 17.5C21 17.7652 20.8946 18.0196 20.7071 18.2071C20.5196 18.3946 20.2652 18.5 20 18.5H12C11.7348 18.5 11.4804 18.3946 11.2929 18.2071C11.1054 18.0196 11 17.7652 11 17.5C11 17.2348 11.1054 16.9804 11.2929 16.7929C11.4804 16.6054 11.7348 16.5 12 16.5H20C20.2652 16.5 20.5196 16.6054 20.7071 16.7929C20.8946 16.9804 21 17.2348 21 17.5ZM21 21.5C21 21.7652 20.8946 22.0196 20.7071 22.2071C20.5196 22.3946 20.2652 22.5 20 22.5H12C11.7348 22.5 11.4804 22.3946 11.2929 22.2071C11.1054 22.0196 11 21.7652 11 21.5C11 21.2348 11.1054 20.9804 11.2929 20.7929C11.4804 20.6054 11.7348 20.5 12 20.5H20C20.2652 20.5 20.5196 20.6054 20.7071 20.7929C20.8946 20.9804 21 21.2348 21 21.5Z" />
  </svg>
)
