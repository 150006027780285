import { SVGProps } from "react"
import { JSX } from "react/jsx-runtime"

export const LoadFileIcon = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M40.0612 15.4388L29.5613 4.93875C29.4218 4.7995 29.2564 4.68908 29.0743 4.6138C28.8922 4.53851 28.697 4.49985 28.5 4.5H10.5C9.70435 4.5 8.94129 4.81607 8.37868 5.37868C7.81607 5.94129 7.5 6.70435 7.5 7.5V40.5C7.5 41.2957 7.81607 42.0587 8.37868 42.6213C8.94129 43.1839 9.70435 43.5 10.5 43.5H37.5C38.2956 43.5 39.0587 43.1839 39.6213 42.6213C40.1839 42.0587 40.5 41.2957 40.5 40.5V16.5C40.5002 16.303 40.4615 16.1078 40.3862 15.9257C40.3109 15.7436 40.2005 15.5782 40.0612 15.4388ZM30 9.62063L35.3794 15H30V9.62063ZM37.5 40.5H10.5V7.5H27V16.5C27 16.8978 27.158 17.2794 27.4393 17.5607C27.7206 17.842 28.1022 18 28.5 18H37.5V40.5ZM31.5 25.5C31.5 25.8978 31.342 26.2794 31.0607 26.5607C30.7794 26.842 30.3978 27 30 27H18C17.6022 27 17.2206 26.842 16.9393 26.5607C16.658 26.2794 16.5 25.8978 16.5 25.5C16.5 25.1022 16.658 24.7206 16.9393 24.4393C17.2206 24.158 17.6022 24 18 24H30C30.3978 24 30.7794 24.158 31.0607 24.4393C31.342 24.7206 31.5 25.1022 31.5 25.5ZM31.5 31.5C31.5 31.8978 31.342 32.2794 31.0607 32.5607C30.7794 32.842 30.3978 33 30 33H18C17.6022 33 17.2206 32.842 16.9393 32.5607C16.658 32.2794 16.5 31.8978 16.5 31.5C16.5 31.1022 16.658 30.7206 16.9393 30.4393C17.2206 30.158 17.6022 30 18 30H30C30.3978 30 30.7794 30.158 31.0607 30.4393C31.342 30.7206 31.5 31.1022 31.5 31.5Z"
      fill="#D1D1D1"
    />
  </svg>
)
