export const CONTENT = {
  HEADING_EVENTS: "События",
  HEADING_TAXES: "Налоговая база",
  HEADING_DATA_SOURCES: "Источники данных",
  HEADING_BANKS: "Банки",
  HEADING_CASHIERS: "Кассы",
  HEADING_MARKETPLACES: "Маркетплейсы",
  HEADING_INCOME: "Операции",
  SIDER_HEADING_EVENTS: "События",
  SIDER_HEADING_TAXES: "Налоговая база",
  SIDER_HEADING_REPORTS: "Отчётность",
  SIDER_HEADING_DOCUMENT: "Документы",
  SIDER_HEADING_PERSONNEL: "Кадры",
  BANK_SBER: "Сбер",
  BANK_VTB: "ВТБ",
  BANK_ALPHA: "Альфа",
  CASHIERS_FIRST: "Первый ОФД",
  MARKETPLACE_WILDBERRIES: "Wildberries",
  BUTTON_DOWNLOAD_KUDIR: "Скачать КУДиР",
  MARKETPLACE_OZON: "Озон",
  BUTTON_ADD_PAYMENT_TEXT: "Добавить уплату",
  BUTTON_ADD_OPERATION: "Добавить данные",
  BUTTON_ENS_TEXT: "Пополнить ЕНС",
  BUTTON_SIDER_ADD_TEXT: "Добавить",
  BUTTON_SIDER_EDIT_TEXT: "Редактировать",
  BUTTON_ADD_SOURCE_NON: "Добавить данные",
  ENS_ANALYSIS_TEXT: "Анализ ЕНС доступен на платном тарифе",
  TEXT_DETAILS: "Подробнее",
  SELECT_ACCOUNT_NUMBER: "Источник данных",
  SELECT_OPERATION_TYPE: "Тип операции",
  SIDER_SETTINGS: "Настройки",
  SIDER_SUPPORT: "Помощь",
  CARD_TAXES_HEADING: "Налог за 9 месяцев",
  CARD_TAXES_DATE: "до 28.10.23",
  CARD_TAXES_PAID: "уже уплатил ",
  CARD_TAXES_BUTTON_PAID: "Оплачено",
  CARD_FIX_PAYMENT: "Фикс. взнос за `23 год",
  CARD_FIX_PAYMENT_DATE: "до 31.12.23",
  CARD_FIX_PAYMENT_PAID: "уже уплатил ",
  CARD_SUPER_PROFITS: "1% с дохода сверх 300\u00A0000\u00A0₽",
  CARD_SUPER_PROFITS_DATE: "до 01.07.24",
  CARD_SUPER_PROFITS_PAID: "уже уплатил ",
  CLIENT_NAME: "ИП Ягодкин И. В.",
  DEFAULT_DATE: "20.09.2023",
  DEFAULT_DATE_STRING: "сентябрь 2023",
  HAND_LOAD_INFO_TOOLTIP:
    "Важно загрузить все операции за квартал. Только в этом случае расчет налога за период может быть корректным.",
  HEADING_HAND_SOURCERS: "Ручная загрузка",
  HEADING_AUTO_SOURCERS: "Автоматическая загрузка",
  NOTIFICATION_MARKUP_SUCCESS: "Тип операции изменен",
  NOTIFICATION_MARKUP_FAILED: "Ошибка изменения типа операции",
  NOTIFICATION_PROCESSING_SUCCESS: "Операция удалена",
  NOTIFICATION_PROCESSING_FAILED:
    "Ошибка удаления операции, попробуйте еще раз",
  TEXT_NON_TAXES: "Нет операций, соответствующих фильтру",
  TEXT_NON_TAXES_OLD:
    "Расчет налогов в сервисе производится на основании анализа операций из различных источников данных. Важно внести все источники и своевременно подгружать информацию из них.",
  OFF_SOURCE: "Скрыть источник",
  OFF_SOURCE_TOOLTIP: "Скрыть",
  DELETE_SOURCE: "Удалить источник",
  DELETE_SOURCE_TOOLTIP: "Удалить",
  CANCEL_INTEGRATION_SOURCE: "Отменить интеграцию",
  CANCEL_INTEGRATION_SOURCE_TOOPLTIP: "Удалить",
  OFF_INTEGRATION_SOURCE: "Отключить интеграцию",
  OFF_INTEGRATION_SOURCE_TOOLTIP: "Отключить",
  ADD_SOURCE_COMPLETED: "Загрузить выписку",
  TOOLTIP_ORANGE: "Требуется дополнительное действие",
  TOOLTIP_GREY: "В процессе обработки",
  TOOLTIP_GREY_AUTO: "В процессе подключения",
  TOOLTIP_DATE: "Дата последней операции",
  TOOLTIP_DISABLE_DATE: "Дата отключения",
  FILTER_HEADING: "Фильтр",
  RESET_FILTERS: "Сбросить фильтр",
  TAXES_ERROR_HEADING: "Что-то пошло не так",
  TAXES_ERROR_DESCRIPTION: "Мы знаем о проблеме и решаем её",
  TITLE_COLLAPSED_SOURCES: "Скрытые источники",
  DELETE_TOOLTIP: "Удалить",
  NON_OPERATIONS:
    "Расчет налогов и взносов производится на основании операций, загруженных в Сервис",
  NON_OPERATIONS_NEED: "Внесите данные для расчета с ",
  NON_OPERATIONS_TITLE: "У вас еще нет загруженных операций",
  SOURCES_LOADED_EMPTY_TITLE: "Список источников",
  SOURCES_LOADED_EMPTY_DESCRIPTION_ONE: "Здесь вы увидите ",

  SOURCES_LOADED_EMPTY_DESCRIPTION_TWO: "банковские счета",
  SOURCES_LOADED_EMPTY_DESCRIPTION_THREE:
    ", по которым были загружены выписки, и ",
  SOURCES_LOADED_EMPTY_DESCRIPTION_FOUR: "интеграции ",
  SOURCES_LOADED_EMPTY_DESCRIPTION_FIVE:
    "с банками, онлайн-кассами, маркетплейсами.",
  SOURCES_LOADED_EMPTY_DESCRIPTION_SIX:
    "Для каждого источника будет отображаться актуальный статус и дата последней операции",
  SOURCES_ERROR_TITLE: "Что-то пошло не так",
  SOURCES_ERROR_ONE:
    "Произошла ошибка при загрузке. Попробуйте обновить список источников по кнопке обновления.",
  SOURCES_ERROR_TWO:
    "Если ошибка повторилась, напишите  тех. специалисту код ошибки. ",
  NO_SOURCE: "—",
  OFF_SOURCE_HIDDEN: "Скрыт",
  OFF_SOURCE_DISABLED: "Закрыт",
  OFF_AUTO_SOURCE_DISABLED: "Отключен",
  TOOLTIP_AUTO_INTEGRATED: "Интеграция подключена",
  TOOLTIP_MARKUP_CHANGED: "Разметка изменена клиентом",
  SOURCE_MOBILE_TITLE: "Источники",
  SOURCE_MOBILE_UPDATE: "Обновить",
}
