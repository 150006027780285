import { SVGProps } from "react"
import { JSX } from "react/jsx-runtime"

interface ArrowCounterIconProps
  extends JSX.IntrinsicAttributes,
    SVGProps<SVGSVGElement> {
  className?: string
}

export const ArrowRoundUpdateIcon = ({
  className = "",
  ...props
}: ArrowCounterIconProps) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={`${className} arrow-color-icon`}
  >
    <path
      d="M12.25 6.99922C12.2501 8.37947 11.7067 9.70425 10.7373 10.6868C9.76798 11.6694 8.45067 12.2307 7.07055 12.2492H7C5.65919 12.2526 4.36859 11.7395 3.39609 10.8164C3.35433 10.7769 3.32076 10.7296 3.29729 10.6771C3.27383 10.6246 3.26092 10.568 3.25933 10.5106C3.25773 10.4531 3.26746 10.3959 3.28797 10.3422C3.30848 10.2885 3.33937 10.2394 3.37887 10.1976C3.41837 10.1559 3.4657 10.1223 3.51818 10.0988C3.57065 10.0754 3.62723 10.0625 3.68469 10.0609C3.74215 10.0593 3.79937 10.069 3.85306 10.0895C3.90676 10.11 3.95589 10.1409 3.99766 10.1804C4.62317 10.7703 5.40864 11.1628 6.25594 11.3089C7.10324 11.455 7.9748 11.3482 8.76175 11.0019C9.54871 10.6555 10.2162 10.0849 10.6807 9.36143C11.1453 8.63795 11.3864 7.79361 11.3739 6.93389C11.3614 6.07418 11.0958 5.23722 10.6104 4.52755C10.125 3.81788 9.44121 3.26697 8.64451 2.94365C7.84782 2.62033 6.97351 2.53893 6.13082 2.70962C5.28813 2.8803 4.51442 3.29551 3.90633 3.90336C3.90186 3.9082 3.89711 3.91276 3.89211 3.91704L2.43852 5.24922H3.9375C4.05353 5.24922 4.16481 5.29532 4.24686 5.37736C4.32891 5.45941 4.375 5.57069 4.375 5.68672C4.375 5.80276 4.32891 5.91404 4.24686 5.99608C4.16481 6.07813 4.05353 6.12422 3.9375 6.12422H1.3125C1.19647 6.12422 1.08519 6.07813 1.00314 5.99608C0.921094 5.91404 0.875 5.80276 0.875 5.68672V3.06172C0.875 2.94569 0.921094 2.83441 1.00314 2.75236C1.08519 2.67032 1.19647 2.62422 1.3125 2.62422C1.42853 2.62422 1.53981 2.67032 1.62186 2.75236C1.70391 2.83441 1.75 2.94569 1.75 3.06172V4.69141L3.29492 3.28047C4.02991 2.54834 4.96533 2.05037 5.9831 1.84943C7.00087 1.64849 8.05536 1.75358 9.01345 2.15144C9.97154 2.54931 10.7903 3.2221 11.3663 4.0849C11.9423 4.94769 12.2498 5.96181 12.25 6.99922Z"
      fill="#6159FF"
    />
  </svg>
)
