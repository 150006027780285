import "./styles.scss"

export const FileLoadedIcon = ({ ...props }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M28.7076 9.70708L12.7076 25.7071C12.6147 25.8001 12.5044 25.8738 12.383 25.9241C12.2616 25.9745 12.1315 26.0004 12.0001 26.0004C11.8687 26.0004 11.7385 25.9745 11.6171 25.9241C11.4957 25.8738 11.3854 25.8001 11.2926 25.7071L4.29257 18.7071C4.10493 18.5194 3.99951 18.2649 3.99951 17.9996C3.99951 17.7342 4.10493 17.4797 4.29257 17.2921C4.48021 17.1044 4.7347 16.999 5.00007 16.999C5.26543 16.999 5.51993 17.1044 5.70757 17.2921L12.0001 23.5858L27.2926 8.29208C27.4802 8.10444 27.7347 7.99902 28.0001 7.99902C28.2654 7.99902 28.5199 8.10444 28.7076 8.29208C28.8952 8.47972 29.0006 8.73422 29.0006 8.99958C29.0006 9.26494 28.8952 9.51944 28.7076 9.70708Z"
      fill="#389E0D"
    />
  </svg>
)
