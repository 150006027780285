import { SVGProps } from "react"
import { JSX } from "react/jsx-runtime"

export const LogoIcon = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) => (
  <svg
    width="218"
    height="44"
    viewBox="0 0 218 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.5115 29.3465H5.16428V26.2112H17.5115V29.3465ZM21.7631 33.6645H17.5406L10.7264 16.6128H11.8912L5.07692 33.6645H1L9.21207 14H13.5511L21.7631 33.6645Z"
      fill="#6159FF"
    />
    <path
      d="M25.9092 33.6645V14H29.8405V30.8592L29.491 30.5292H34.8202C35.8879 30.5292 36.7324 30.2816 37.3537 29.7866C37.9749 29.2732 38.2855 28.5765 38.2855 27.6964C38.2855 26.8346 37.9749 26.1654 37.3537 25.6887C36.7324 25.212 35.8879 24.9736 34.8202 24.9736H27.802V21.8383H34.791C36.3053 21.8383 37.6158 22.0766 38.7223 22.5534C39.8483 23.0301 40.722 23.7085 41.3432 24.5886C41.9645 25.4686 42.2751 26.5046 42.2751 27.6964C42.2751 28.8698 41.9645 29.9058 41.3432 30.8042C40.722 31.7026 39.8483 32.4085 38.7223 32.9219C37.5963 33.4169 36.2859 33.6645 34.791 33.6645H25.9092ZM27.9185 17.1628V14H40.6443V17.1628H27.9185Z"
      fill="#505050"
    />
    <path
      d="M55.4145 19.8306H59.2876L52.3277 35.2596C51.7647 36.4881 51.0561 37.414 50.2019 38.0374C49.3477 38.6791 48.2119 39 46.7947 39H44.9019V36.0297H46.5909C47.1733 36.0297 47.6586 35.9014 48.0469 35.6447C48.4546 35.4063 48.7749 35.0213 49.0079 34.4895L49.3282 33.692L43.0673 19.8306H47.0568L51.8326 31.6293H50.5804L55.4145 19.8306Z"
      fill="#505050"
    />
    <path
      d="M64.2661 33.6645H59.9271L65.5183 26.4862L60.393 19.8306H64.7029L67.9353 24.3685L71.1968 19.8306H75.5358L70.3815 26.4862L75.9727 33.6645H71.6045L67.9353 28.659L64.2661 33.6645Z"
      fill="#505050"
    />
    <path
      d="M78.3094 33.6645V19.8306H89.0841V22.7459H79.183L82.1533 20.5457V33.6645H78.3094Z"
      fill="#505050"
    />
    <path
      d="M104.527 33.6645H101.032V30.8867L100.857 30.4466V25.2486C100.857 24.2769 100.527 23.5251 99.8672 22.9934C99.2071 22.4617 98.3044 22.1958 97.159 22.1958C96.3242 22.1958 95.5379 22.3517 94.8002 22.6634C94.0819 22.9567 93.4509 23.3784 92.9073 23.9285L90.898 21.7283C91.6745 20.9949 92.6161 20.4265 93.7227 20.0231C94.8293 19.6014 96.033 19.3905 97.3337 19.3905C98.7509 19.3905 100.003 19.6197 101.09 20.0781C102.177 20.5365 103.022 21.1966 103.624 22.0583C104.226 22.9017 104.527 23.9377 104.527 25.1661V33.6645ZM96.0524 34.1045C94.9264 34.1045 93.9363 33.912 93.082 33.527C92.2278 33.1419 91.5581 32.6102 91.0727 31.9318C90.6068 31.2534 90.3738 30.465 90.3738 29.5666C90.3738 28.6131 90.665 27.8064 91.2474 27.1463C91.8298 26.4679 92.6549 25.9454 93.7227 25.5787C94.8099 25.212 96.0621 25.0286 97.4793 25.0286H101.236V27.3938H97.7705C96.7222 27.3938 95.8776 27.568 95.237 27.9164C94.5963 28.2648 94.276 28.769 94.276 29.429C94.276 30.0341 94.5187 30.5108 95.004 30.8592C95.5088 31.2076 96.1591 31.3817 96.9551 31.3817C97.7123 31.3817 98.4015 31.2351 99.0227 30.9417C99.6439 30.63 100.149 30.2083 100.537 29.6766C100.925 29.1448 101.149 28.5398 101.207 27.8614L102.313 28.3564C102.313 29.4932 102.051 30.4925 101.527 31.3542C101.003 32.216 100.265 32.8944 99.3139 33.3894C98.382 33.8662 97.2949 34.1045 96.0524 34.1045Z"
      fill="#505050"
    />
    <path
      d="M106.542 33.6645V30.6942H107.532C108.076 30.6942 108.464 30.5842 108.697 30.3641C108.95 30.1441 109.105 29.7682 109.163 29.2365L110.095 19.8306H121.598V33.6645H117.754V20.3531L120.52 22.7459H110.765L113.706 20.3531L112.687 29.9791C112.531 31.3726 112.104 32.3352 111.405 32.8669C110.726 33.3986 109.668 33.6645 108.231 33.6645H106.542Z"
      fill="#505050"
    />
    <path
      d="M129.213 33.6645V21.0682H133.057V33.6645H129.213ZM124.263 22.7459V19.8306H138.008V22.7459H124.263Z"
      fill="#505050"
    />
    <path
      d="M152.395 29.3465L154.434 31.5743C153.696 32.3627 152.745 32.9861 151.58 33.4444C150.435 33.8845 149.163 34.1045 147.765 34.1045C146.076 34.1045 144.591 33.7928 143.31 33.1694C142.028 32.546 141.029 31.6843 140.31 30.5842C139.592 29.484 139.233 28.2098 139.233 26.7613C139.233 25.3128 139.573 24.0385 140.252 22.9384C140.951 21.8199 141.893 20.949 143.077 20.3256C144.28 19.7022 145.63 19.3905 147.125 19.3905C148.581 19.3905 149.872 19.7022 150.998 20.3256C152.143 20.9307 153.036 21.7741 153.677 22.8559C154.317 23.9377 154.638 25.1936 154.638 26.6238V26.7888H150.881V26.4037C150.881 25.5603 150.716 24.8269 150.386 24.2035C150.076 23.5801 149.639 23.0942 149.076 22.7459C148.532 22.3975 147.882 22.2233 147.125 22.2233C146.329 22.2233 145.63 22.4067 145.028 22.7734C144.426 23.1217 143.95 23.6351 143.601 24.3135C143.271 24.9736 143.106 25.762 143.106 26.6788C143.106 27.6505 143.31 28.4848 143.717 29.1815C144.145 29.8599 144.717 30.3825 145.436 30.7492C146.173 31.0975 147.018 31.2717 147.969 31.2717C149.716 31.2717 151.192 30.63 152.395 29.3465ZM154.638 27.8614H141.708V25.5512H154.172L154.638 26.6238V27.8614Z"
      fill="#505050"
    />
    <path
      d="M161.695 39H157.851V19.8306H161.666V24.121H160.851C160.987 23.2226 161.307 22.4158 161.812 21.7008C162.336 20.9857 163.015 20.4265 163.85 20.0231C164.685 19.6014 165.636 19.3905 166.704 19.3905C168.044 19.3905 169.267 19.7022 170.373 20.3256C171.48 20.949 172.353 21.8108 172.994 22.9109C173.654 24.011 173.984 25.2945 173.984 26.7613C173.984 28.2098 173.654 29.484 172.994 30.5842C172.334 31.6843 171.451 32.546 170.344 33.1694C169.238 33.7928 167.995 34.1045 166.617 34.1045C165.355 34.1045 164.268 33.8478 163.355 33.3344C162.443 32.821 161.754 32.1335 161.288 31.2717C160.822 30.3916 160.618 29.429 160.676 28.3839L161.491 27.8889C161.588 28.5123 161.831 29.0715 162.219 29.5666C162.608 30.0433 163.103 30.4191 163.705 30.6942C164.326 30.9509 164.996 31.0792 165.714 31.0792C166.568 31.0792 167.316 30.8959 167.956 30.5292C168.597 30.1624 169.092 29.6491 169.441 28.989C169.81 28.3289 169.995 27.5864 169.995 26.7613C169.995 25.8995 169.82 25.1478 169.471 24.5061C169.14 23.846 168.665 23.3326 168.044 22.9659C167.442 22.5992 166.723 22.4158 165.889 22.4158C165.073 22.4158 164.345 22.5992 163.705 22.9659C163.083 23.3326 162.588 23.846 162.219 24.5061C161.87 25.1478 161.695 25.927 161.695 26.8438V39Z"
      fill="#505050"
    />
    <path
      d="M177.132 33.6645V19.8306H180.802V29.044L180.598 28.989L187.936 19.8306H191.314V33.6645H187.645V24.451L187.849 24.5061L180.51 33.6645H177.132Z"
      fill="#505050"
    />
    <path
      d="M204.477 33.6645V20.2981L206.952 22.5259H201.07C200.39 22.5259 199.827 22.6725 199.381 22.9659C198.954 23.2593 198.74 23.7176 198.74 24.341C198.74 24.9461 198.944 25.4045 199.352 25.7162C199.779 26.0279 200.371 26.1837 201.128 26.1837H205.904V28.6865H200.604C198.876 28.6865 197.498 28.2923 196.469 27.5038C195.44 26.7154 194.925 25.6428 194.925 24.286C194.925 23.4243 195.168 22.6634 195.653 22.0033C196.158 21.3249 196.847 20.7932 197.721 20.4081C198.595 20.0231 199.594 19.8306 200.72 19.8306H208.001V33.6645H204.477ZM194.197 33.6645L199.09 27.6689H202.934L198.362 33.6645H194.197Z"
      fill="#505050"
    />
  </svg>
)
