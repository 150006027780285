import { SVGProps } from "react"
import { JSX } from "react/jsx-runtime"

export const PersonEditIcon = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9 9.5625C6.6225 9.5625 4.6875 7.6275 4.6875 5.25C4.6875 2.8725 6.6225 0.9375 9 0.9375C11.3775 0.9375 13.3125 2.8725 13.3125 5.25C13.3125 7.6275 11.3775 9.5625 9 9.5625ZM9 2.0625C7.245 2.0625 5.8125 3.495 5.8125 5.25C5.8125 7.005 7.245 8.4375 9 8.4375C10.755 8.4375 12.1875 7.005 12.1875 5.25C12.1875 3.495 10.755 2.0625 9 2.0625Z"
      fill="#8C8C8C"
    />
    <path
      d="M11.8652 17.0616C11.5802 17.0616 11.3102 16.9566 11.1152 16.7616C10.8827 16.5291 10.7777 16.1916 10.8302 15.8391L10.9727 14.8266C11.0102 14.5641 11.1677 14.2566 11.3552 14.0616L14.0101 11.4066C15.0751 10.3416 16.0127 10.9491 16.4702 11.4066C16.8602 11.7966 17.0626 12.2166 17.0626 12.6366C17.0626 13.0641 16.8677 13.4616 16.4702 13.8591L13.8151 16.5141C13.6276 16.7016 13.3127 16.8591 13.0502 16.8966L12.0376 17.0391C11.9776 17.0541 11.9252 17.0616 11.8652 17.0616ZM15.2326 11.9391C15.0976 11.9391 14.9776 12.0291 14.8051 12.2016L12.1502 14.8566C12.1277 14.8791 12.0901 14.9541 12.0901 14.9841L11.9552 15.9216L12.8927 15.7866C12.9227 15.7791 12.9976 15.7416 13.0201 15.7191L15.6752 13.0641C15.7952 12.9441 15.9376 12.7716 15.9376 12.6366C15.9376 12.5241 15.8477 12.3666 15.6752 12.2016C15.4952 12.0216 15.3601 11.9391 15.2326 11.9391Z"
      fill="#8C8C8C"
    />
    <path
      d="M15.6899 14.415C15.6374 14.415 15.5849 14.4075 15.5399 14.3925C14.5499 14.115 13.7624 13.3275 13.4849 12.3375C13.4024 12.0375 13.5749 11.73 13.8749 11.6475C14.1749 11.565 14.4824 11.7375 14.5649 12.0375C14.7374 12.6525 15.2249 13.14 15.8399 13.3125C16.1399 13.395 16.3124 13.71 16.2299 14.0025C16.1624 14.25 15.9374 14.415 15.6899 14.415Z"
      fill="#8C8C8C"
    />
    <path
      d="M2.55762 17.0625C2.25012 17.0625 1.99512 16.8075 1.99512 16.5C1.99512 13.2975 5.13763 10.6875 9.00015 10.6875C9.81765 10.6875 10.6276 10.8075 11.3851 11.0325C11.6851 11.1225 11.8501 11.4375 11.7601 11.73C11.6701 12.03 11.3551 12.195 11.0626 12.105C10.4026 11.91 9.71265 11.805 9.00015 11.805C5.76013 11.805 3.12012 13.905 3.12012 16.4925C3.12012 16.8075 2.86512 17.0625 2.55762 17.0625Z"
      fill="#8C8C8C"
    />
  </svg>
)
