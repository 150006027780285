import { SVGProps } from "react"
import { JSX } from "react/jsx-runtime"

export const LogoMainIcon = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) => (
  <svg
    width="391"
    height="48"
    viewBox="0 0 391 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M31.1884 29.4653H7.86586V23.4455H31.1884V29.4653ZM39.2193 37.7558H31.2434L18.372 5.0165H20.5722L7.70084 37.7558H0L15.5117 0H23.7076L39.2193 37.7558Z"
      fill="#6159FF"
    />
    <path
      d="M47.0503 37.7558V0H54.4761V32.3696L53.816 31.736H63.8821C65.899 31.736 67.4942 31.2607 68.6677 30.3102C69.8411 29.3245 70.4278 27.9868 70.4278 26.297C70.4278 24.6425 69.8411 23.3575 68.6677 22.4422C67.4942 21.527 65.899 21.0693 63.8821 21.0693H50.6257V15.0495H63.8271C66.6874 15.0495 69.1627 15.5072 71.2529 16.4224C73.3798 17.3377 75.03 18.6403 76.2035 20.33C77.3769 22.0198 77.9637 24.0088 77.9637 26.297C77.9637 28.5501 77.3769 30.5391 76.2035 32.264C75.03 33.989 73.3798 35.3443 71.2529 36.33C69.126 37.2805 66.6508 37.7558 63.8271 37.7558H47.0503ZM50.8457 6.07261V0H74.8833V6.07261H50.8457Z"
      fill="#505050"
    />
    <path
      d="M102.783 11.1947H110.098L96.9519 40.8185C95.8885 43.1771 94.55 44.9549 92.9365 46.1518C91.323 47.3839 89.1777 48 86.5008 48H82.9254V42.297H86.1157C87.2159 42.297 88.1326 42.0506 88.866 41.5578C89.6361 41.1001 90.2412 40.3608 90.6812 39.3399L91.2863 37.8086L79.46 11.1947H86.9958L96.0168 33.8482H93.6516L102.783 11.1947Z"
      fill="#505050"
    />
    <path
      d="M119.502 37.7558H111.306L121.867 23.9736L112.186 11.1947H120.327L126.433 19.9076L132.594 11.1947H140.79L131.053 23.9736L141.615 37.7558H133.364L126.433 28.1452L119.502 37.7558Z"
      fill="#505050"
    />
    <path
      d="M146.028 37.7558V11.1947H166.381V16.7921H147.679L153.289 12.5677V37.7558H146.028Z"
      fill="#505050"
    />
    <path
      d="M195.55 37.7558H188.949V32.4224L188.619 31.5776V21.5974C188.619 19.7316 187.996 18.2882 186.749 17.2673C185.502 16.2464 183.797 15.736 181.633 15.736C180.056 15.736 178.571 16.0352 177.178 16.6337C175.821 17.1969 174.629 18.0066 173.602 19.0627L169.807 14.8383C171.274 13.4301 173.052 12.3388 175.143 11.5644C177.233 10.7547 179.506 10.3498 181.963 10.3498C184.64 10.3498 187.005 10.7899 189.059 11.67C191.113 12.5501 192.708 13.8174 193.845 15.4719C194.981 17.0913 195.55 19.0803 195.55 21.4389V37.7558ZM179.543 38.6007C177.416 38.6007 175.546 38.231 173.932 37.4917C172.319 36.7525 171.054 35.7316 170.137 34.429C169.257 33.1265 168.817 31.6128 168.817 29.8878C168.817 28.0572 169.367 26.5082 170.467 25.2409C171.567 23.9384 173.126 22.9351 175.143 22.231C177.196 21.527 179.561 21.1749 182.238 21.1749H189.334V25.7162H182.788C180.808 25.7162 179.213 26.0506 178.003 26.7195C176.793 27.3883 176.188 28.3564 176.188 29.6238C176.188 30.7855 176.646 31.7008 177.563 32.3696C178.516 33.0385 179.745 33.3729 181.248 33.3729C182.678 33.3729 183.98 33.0913 185.154 32.5281C186.327 31.9296 187.281 31.1199 188.014 30.099C188.747 29.0781 189.169 27.9164 189.279 26.6139L191.369 27.5644C191.369 29.747 190.874 31.6656 189.884 33.3201C188.894 34.9747 187.501 36.2772 185.704 37.2277C183.943 38.143 181.89 38.6007 179.543 38.6007Z"
      fill="#505050"
    />
    <path
      d="M199.357 37.7558V32.0528H201.227C202.254 32.0528 202.988 31.8416 203.428 31.4191C203.904 30.9967 204.198 30.275 204.308 29.2541L206.068 11.1947H227.795V37.7558H220.535V12.198L225.76 16.7921H207.333L212.889 12.198L210.963 30.6799C210.67 33.3553 209.863 35.2035 208.543 36.2244C207.26 37.2453 205.261 37.7558 202.548 37.7558H199.357Z"
      fill="#505050"
    />
    <path
      d="M242.18 37.7558V13.571H249.441V37.7558H242.18ZM232.829 16.7921V11.1947H258.792V16.7921H232.829Z"
      fill="#505050"
    />
    <path
      d="M285.969 29.4653L289.819 33.7426C288.426 35.2563 286.629 36.4532 284.429 37.3333C282.265 38.1782 279.863 38.6007 277.223 38.6007C274.033 38.6007 271.227 38.0022 268.807 36.8053C266.387 35.6084 264.498 33.9538 263.141 31.8416C261.785 29.7294 261.106 27.2827 261.106 24.5016C261.106 21.7206 261.748 19.2739 263.031 17.1617C264.352 15.0143 266.13 13.3421 268.367 12.1452C270.641 10.9483 273.189 10.3498 276.013 10.3498C278.763 10.3498 281.202 10.9483 283.329 12.1452C285.492 13.3069 287.179 14.9263 288.389 17.0033C289.599 19.0803 290.204 21.4917 290.204 24.2376V24.5545H283.109V23.8152C283.109 22.1958 282.797 20.7877 282.173 19.5908C281.587 18.3938 280.762 17.4609 279.698 16.7921C278.671 16.1232 277.443 15.7888 276.013 15.7888C274.509 15.7888 273.189 16.1408 272.052 16.8449C270.916 17.5138 270.017 18.4995 269.357 19.802C268.734 21.0693 268.422 22.5831 268.422 24.3432C268.422 26.209 268.807 27.8108 269.577 29.1485C270.384 30.451 271.466 31.4543 272.822 32.1584C274.216 32.8273 275.811 33.1617 277.608 33.1617C280.908 33.1617 283.695 31.9296 285.969 29.4653ZM290.204 26.6139H265.782V22.1782H289.324L290.204 24.2376V26.6139Z"
      fill="#505050"
    />
    <path
      d="M303.535 48H296.274V11.1947H303.48V19.4323H301.94C302.197 17.7074 302.802 16.1584 303.755 14.7855C304.745 13.4125 306.029 12.3388 307.606 11.5644C309.182 10.7547 310.979 10.3498 312.996 10.3498C315.526 10.3498 317.837 10.9483 319.927 12.1452C322.017 13.3421 323.667 14.9967 324.877 17.1089C326.124 19.2211 326.748 21.6854 326.748 24.5016C326.748 27.2827 326.124 29.7294 324.877 31.8416C323.631 33.9538 321.962 35.6084 319.872 36.8053C317.782 38.0022 315.435 38.6007 312.831 38.6007C310.448 38.6007 308.394 38.1078 306.67 37.1221C304.947 36.1364 303.645 34.8163 302.765 33.1617C301.885 31.4719 301.5 29.6238 301.61 27.6172L303.15 26.6667C303.333 27.8636 303.792 28.9373 304.525 29.8878C305.259 30.8031 306.194 31.5248 307.331 32.0528C308.504 32.5457 309.769 32.7921 311.126 32.7921C312.739 32.7921 314.151 32.44 315.361 31.736C316.572 31.0319 317.507 30.0462 318.167 28.7789C318.863 27.5116 319.212 26.0858 319.212 24.5016C319.212 22.8471 318.882 21.4037 318.222 20.1716C317.598 18.9043 316.7 17.9186 315.526 17.2145C314.39 16.5105 313.033 16.1584 311.456 16.1584C309.916 16.1584 308.541 16.5105 307.331 17.2145C306.157 17.9186 305.222 18.9043 304.525 20.1716C303.865 21.4037 303.535 22.8999 303.535 24.6601V48Z"
      fill="#505050"
    />
    <path
      d="M332.694 37.7558V11.1947H339.625V28.8845L339.24 28.7789L353.102 11.1947H359.482V37.7558H352.551V20.066L352.937 20.1716L339.075 37.7558H332.694Z"
      fill="#505050"
    />
    <path
      d="M384.345 37.7558V12.0924L389.02 16.3696H377.909C376.626 16.3696 375.562 16.6513 374.719 17.2145C373.912 17.7778 373.509 18.6579 373.509 19.8548C373.509 21.0165 373.894 21.8966 374.664 22.4951C375.471 23.0935 376.589 23.3927 378.019 23.3927H387.04V28.198H377.029C373.765 28.198 371.162 27.4411 369.218 25.9274C367.275 24.4136 366.303 22.3542 366.303 19.7492C366.303 18.0946 366.761 16.6337 367.678 15.3663C368.632 14.0638 369.933 13.0429 371.584 12.3036C373.234 11.5644 375.122 11.1947 377.249 11.1947H391.001V37.7558H384.345ZM364.928 37.7558L374.169 26.2442H381.43L372.794 37.7558H364.928Z"
      fill="#505050"
    />
  </svg>
)
