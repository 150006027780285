import { SVGProps } from "react"
import { JSX } from "react/jsx-runtime"

export const InProgressIcon = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.99994 13.3331C6.12383 13.3331 5.3005 13.1669 4.52996 12.8345C3.7594 12.502 3.08913 12.0509 2.51913 11.481C1.94913 10.9111 1.49788 10.2409 1.16538 9.47049C0.832876 8.70008 0.666626 7.87689 0.666626 7.00094C0.666626 6.12498 0.832848 5.30163 1.16529 4.53087C1.49774 3.7601 1.9489 3.08965 2.51879 2.5195C3.08869 1.94935 3.75885 1.49798 4.52928 1.16539C5.29969 0.832799 6.12287 0.666504 6.99883 0.666504C7.87478 0.666504 8.69814 0.832755 9.46889 1.16525C10.2397 1.49775 10.9101 1.949 11.4803 2.519C12.0504 3.089 12.5018 3.75928 12.8344 4.52984C13.167 5.30038 13.3333 6.12371 13.3333 6.99982C13.3333 7.29982 13.3145 7.59427 13.2768 7.88315C13.2392 8.17204 13.1794 8.45538 13.0974 8.73315C12.976 8.60667 12.8392 8.49834 12.6871 8.40817C12.535 8.31799 12.3687 8.25496 12.1884 8.21907C12.2388 8.02248 12.2756 7.82312 12.2987 7.62099C12.3217 7.41884 12.3333 7.21179 12.3333 6.99982C12.3333 5.51093 11.8166 4.24982 10.7833 3.21649C9.74994 2.18315 8.48883 1.66649 6.99994 1.66649C5.51105 1.66649 4.24994 2.18315 3.21661 3.21649C2.18328 4.24982 1.66661 5.51093 1.66661 6.99982C1.66661 8.48871 2.18328 9.74982 3.21661 10.7832C4.24994 11.8165 5.51105 12.3332 6.99994 12.3332C7.58371 12.3332 8.13919 12.2453 8.66639 12.0697C9.19361 11.894 9.6783 11.6481 10.1205 11.3319C10.2239 11.4695 10.345 11.5934 10.4839 11.7037C10.6228 11.8139 10.7734 11.9015 10.9358 11.9665C10.3948 12.3964 9.79197 12.7316 9.12733 12.9722C8.46268 13.2128 7.75355 13.3331 6.99994 13.3331ZM11.7375 10.8331C11.5509 10.8331 11.3931 10.7687 11.264 10.6399C11.135 10.5111 11.0705 10.3534 11.0705 10.1669C11.0705 9.98029 11.1349 9.82248 11.2637 9.69342C11.3925 9.56437 11.5502 9.49984 11.7368 9.49984C11.9233 9.49984 12.0811 9.56424 12.2102 9.69305C12.3392 9.82188 12.4038 9.97957 12.4038 10.1661C12.4038 10.3527 12.3394 10.5105 12.2105 10.6396C12.0817 10.7686 11.924 10.8331 11.7375 10.8331ZM9.31534 10.0178L6.49996 7.20225V3.66649H7.49993V6.79726L10.0179 9.31522L9.31534 10.0178Z"
      fill="#8C8C8C"
    />
  </svg>
)
