export const CONTENT = {
  HEADING_EVENTS: "События",
  HEADING_TAXES: "Налоговая база",
  HEADING_DATA_SOURCES: "Источники данных",
  HEADING_BANKS: "Банки",
  HEADING_CASHIERS: "Кассы",
  HEADING_MARKETPLACES: "Маркетплейсы",
  HEADING_INCOME: "Доходы",
  SIDER_HEADING_EVENTS: "События",
  SIDER_HEADING_TAXES: "Операции",
  SIDER_HEADING_REPORTS: "Отчётность",
  SIDER_HEADING_DOCUMENTS: "Документы",
  SIDER_HEADING_PERSONAL: "Кадры",
  BANK_SBER: "Сбер",
  BANK_VTB: "ВТБ",
  BANK_ALPHA: "Альфа",
  CASHIERS_FIRST: "Первый ОФД",
  MARKETPLACE_WILDBERRIES: "Wildberries",
  MARKETPLACE_OZON: "Озон",
  BUTTON_ADD_PAYMENT_TEXT: "Добавить уплату",
  BUTTON_ENS_TEXT: "Пополнить ЕНС",
  BUTTON_SIDER_ADD_TEXT: "Добавить",
  BUTTON_SIDER_EDIT_TEXT: "Редактировать",
  ENS_ANALYSIS_TEXT: "Анализ ЕНС доступен на платном тарифе",
  TEXT_DETAILS: "Подробнее",
  SELECT_ACCOUNT_NUMBER: "Номер счета",
  SELECT_OPERATION_TYPE: "Тип операции",
  SIDER_SETTINGS: "Настройки",
  SIDER_SUPPORT: "Помощь",
  CARD_TAXES_HEADING: "Налог за 9 месяцев",
  CARD_TAXES_DATE: "до 28.10.23",
  CARD_TAXES_PAID: "уже уплатил ",
  CARD_TAXES_BUTTON_PAID: "Оплачено",
  CARD_FIX_PAYMENT: "Фикс. взнос за `23 год",
  CARD_FIX_PAYMENT_DATE: "до 31.12.23",
  CARD_FIX_PAYMENT_PAID: "уже уплатил ",
  CARD_SUPER_PROFITS: "1% с дохода сверх 300\u00A0000\u00A0₽",
  CARD_SUPER_PROFITS_DATE: "до 01.07.24",
  CARD_SUPER_PROFITS_PAID: "уже уплатил ",
  CLIENT_NAME: "ИП Ягодкин И. В.",
  DEFAULT_DATE: "20.09.2023",
  DEFAULT_DATE_STRING: "сентябрь 2023",
}

export const TAX_SYSTEM: Record<string, string> = {
  usn_d: "УСН Доходы",
  usn_d_r: "УСН Доходы - Расходы",
  patent: "Патент",
  eshn: "ЕСХН",
  osn: "Общая система НО",
}
