import { SVGProps } from "react"
import { JSX } from "react/jsx-runtime"

export const LogoRegisterImage = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) => (
  <svg
    width="196"
    height="24"
    viewBox="0 0 196 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.5885 14.7327H3.93149V11.7228H15.5885V14.7327ZM19.6025 18.8779H15.616L9.18265 2.50825H10.2824L3.84901 18.8779H0L7.75301 0H11.8495L19.6025 18.8779Z"
      fill="#6159FF"
    />
    <path
      d="M23.5166 18.8779V0H27.2282V16.1848L26.8982 15.868H31.9294C32.9375 15.868 33.7348 15.6304 34.3213 15.1551C34.9078 14.6623 35.2011 13.9934 35.2011 13.1485C35.2011 12.3212 34.9078 11.6788 34.3213 11.2211C33.7348 10.7635 32.9375 10.5347 31.9294 10.5347H25.3036V7.52475H31.902C33.3316 7.52475 34.5688 7.75358 35.6135 8.21122C36.6766 8.66887 37.5014 9.32013 38.0879 10.165C38.6744 11.0099 38.9676 12.0044 38.9676 13.1485C38.9676 14.275 38.6744 15.2695 38.0879 16.132C37.5014 16.9945 36.6766 17.6722 35.6135 18.165C34.5504 18.6403 33.3133 18.8779 31.902 18.8779H23.5166ZM25.4136 3.0363V0H37.428V3.0363H25.4136Z"
      fill="#505050"
    />
    <path
      d="M51.3725 5.59736H55.0291L48.4583 20.4092C47.9268 21.5886 47.2578 22.4774 46.4513 23.0759C45.6448 23.692 44.5726 24 43.2346 24H41.4476V21.1485H43.0422C43.592 21.1485 44.0503 21.0253 44.4168 20.7789C44.8017 20.5501 45.1042 20.1804 45.3241 19.67L45.6265 18.9043L39.7155 5.59736H43.4821L47.9909 16.9241H46.8087L51.3725 5.59736Z"
      fill="#505050"
    />
    <path
      d="M59.7293 18.8779H55.6329L60.9115 11.9868L56.0728 5.59736H60.1417L63.1934 9.9538L66.2727 5.59736H70.3691L65.5028 11.9868L70.7815 18.8779H66.6576L63.1934 14.0726L59.7293 18.8779Z"
      fill="#505050"
    />
    <path
      d="M72.9876 18.8779V5.59736H83.16V8.39604H73.8124L76.6167 6.28383V18.8779H72.9876Z"
      fill="#505050"
    />
    <path
      d="M97.7392 18.8779H94.44V16.2112L94.2751 15.7888V10.7987C94.2751 9.86579 93.9635 9.14411 93.3403 8.63366C92.7171 8.12321 91.8649 7.86799 90.7835 7.86799C89.9953 7.86799 89.253 8.0176 88.5565 8.31683C87.8784 8.59846 87.2827 9.0033 86.7695 9.53135L84.8725 7.41914C85.6056 6.71507 86.4946 6.16942 87.5393 5.78218C88.584 5.37734 89.7204 5.17492 90.9484 5.17492C92.2864 5.17492 93.4686 5.39494 94.495 5.83498C95.5214 6.27503 96.3187 6.90869 96.8869 7.73597C97.4551 8.54565 97.7392 9.54015 97.7392 10.7195V18.8779ZM89.7387 19.3003C88.6757 19.3003 87.7409 19.1155 86.9345 18.7459C86.128 18.3762 85.4957 17.8658 85.0375 17.2145C84.5976 16.5633 84.3776 15.8064 84.3776 14.9439C84.3776 14.0286 84.6525 13.2541 85.2024 12.6205C85.7523 11.9692 86.5312 11.4675 87.5393 11.1155C88.5657 10.7635 89.7479 10.5875 91.0859 10.5875H94.6325V12.8581H91.3608C90.3711 12.8581 89.5738 13.0253 88.9689 13.3597C88.3641 13.6942 88.0617 14.1782 88.0617 14.8119C88.0617 15.3927 88.2908 15.8504 88.749 16.1848C89.2255 16.5193 89.8396 16.6865 90.591 16.6865C91.3058 16.6865 91.9565 16.5457 92.543 16.264C93.1295 15.9648 93.6061 15.56 93.9727 15.0495C94.3392 14.5391 94.55 13.9582 94.605 13.3069L95.6497 13.7822C95.6497 14.8735 95.4023 15.8328 94.9074 16.6601C94.4125 17.4873 93.7161 18.1386 92.818 18.6139C91.9382 19.0715 90.9118 19.3003 89.7387 19.3003Z"
      fill="#505050"
    />
    <path
      d="M99.6422 18.8779V16.0264H100.577C101.09 16.0264 101.457 15.9208 101.677 15.7096C101.915 15.4983 102.062 15.1375 102.117 14.6271L102.996 5.59736H113.856V18.8779H110.227V6.09901L112.839 8.39604H103.629L106.405 6.09901L105.443 15.3399C105.297 16.6777 104.893 17.6018 104.234 18.1122C103.592 18.6227 102.593 18.8779 101.237 18.8779H99.6422Z"
      fill="#505050"
    />
    <path
      d="M121.046 18.8779V6.78548H124.675V18.8779H121.046ZM116.372 8.39604V5.59736H129.349V8.39604H116.372Z"
      fill="#505050"
    />
    <path
      d="M142.932 14.7327L144.857 16.8713C144.16 17.6282 143.262 18.2266 142.162 18.6667C141.081 19.0891 139.881 19.3003 138.561 19.3003C136.966 19.3003 135.564 19.0011 134.354 18.4026C133.145 17.8042 132.201 16.9769 131.523 15.9208C130.844 14.8647 130.505 13.6414 130.505 12.2508C130.505 10.8603 130.826 9.63696 131.468 8.58086C132.128 7.50715 133.016 6.67107 134.134 6.07261C135.271 5.47415 136.545 5.17492 137.956 5.17492C139.331 5.17492 140.55 5.47415 141.613 6.07261C142.694 6.65347 143.537 7.46315 144.142 8.50165C144.747 9.54015 145.049 10.7459 145.049 12.1188V12.2772H141.503V11.9076C141.503 11.0979 141.347 10.3938 141.035 9.79538C140.742 9.19692 140.33 8.73047 139.798 8.39604C139.285 8.06161 138.671 7.89439 137.956 7.89439C137.205 7.89439 136.545 8.07041 135.977 8.42244C135.408 8.75688 134.959 9.24973 134.629 9.90099C134.318 10.5347 134.162 11.2915 134.162 12.1716C134.162 13.1045 134.354 13.9054 134.739 14.5743C135.143 15.2255 135.683 15.7272 136.361 16.0792C137.058 16.4136 137.855 16.5809 138.753 16.5809C140.403 16.5809 141.796 15.9648 142.932 14.7327ZM145.049 13.3069H132.842V11.0891H144.609L145.049 12.1188V13.3069Z"
      fill="#505050"
    />
    <path
      d="M151.712 24H148.083V5.59736H151.685V9.71617H150.915C151.043 8.85369 151.346 8.07921 151.822 7.39274C152.317 6.70627 152.958 6.16942 153.747 5.78218C154.535 5.37734 155.433 5.17492 156.441 5.17492C157.706 5.17492 158.86 5.47415 159.905 6.07261C160.95 6.67107 161.775 7.49835 162.379 8.55445C163.003 9.61056 163.314 10.8427 163.314 12.2508C163.314 13.6414 163.003 14.8647 162.379 15.9208C161.756 16.9769 160.922 17.8042 159.878 18.4026C158.833 19.0011 157.66 19.3003 156.358 19.3003C155.167 19.3003 154.141 19.0539 153.279 18.5611C152.418 18.0682 151.767 17.4081 151.327 16.5809C150.887 15.736 150.695 14.8119 150.75 13.8086L151.52 13.3333C151.611 13.9318 151.84 14.4686 152.207 14.9439C152.574 15.4015 153.041 15.7624 153.609 16.0264C154.196 16.2728 154.828 16.396 155.506 16.396C156.313 16.396 157.018 16.22 157.623 15.868C158.228 15.516 158.695 15.0231 159.025 14.3894C159.374 13.7558 159.548 13.0429 159.548 12.2508C159.548 11.4235 159.383 10.7019 159.053 10.0858C158.741 9.45215 158.292 8.9593 157.706 8.60726C157.137 8.25523 156.459 8.07921 155.671 8.07921C154.901 8.07921 154.214 8.25523 153.609 8.60726C153.023 8.9593 152.555 9.45215 152.207 10.0858C151.877 10.7019 151.712 11.4499 151.712 12.33V24Z"
      fill="#505050"
    />
    <path
      d="M166.286 18.8779V5.59736H169.751V14.4422L169.558 14.3894L176.486 5.59736H179.675V18.8779H176.211V10.033L176.404 10.0858L169.476 18.8779H166.286Z"
      fill="#505050"
    />
    <path
      d="M192.102 18.8779V6.0462L194.439 8.18482H188.886C188.244 8.18482 187.713 8.32563 187.291 8.60726C186.888 8.88889 186.686 9.32893 186.686 9.92739C186.686 10.5083 186.879 10.9483 187.264 11.2475C187.667 11.5468 188.226 11.6964 188.941 11.6964H193.449V14.099H188.446C186.814 14.099 185.513 13.7206 184.542 12.9637C183.57 12.2068 183.085 11.1771 183.085 9.87459C183.085 9.0473 183.314 8.31683 183.772 7.68317C184.248 7.0319 184.899 6.52145 185.724 6.15181C186.549 5.78218 187.493 5.59736 188.556 5.59736H195.429V18.8779H192.102ZM182.397 18.8779L187.016 13.1221H190.645L186.329 18.8779H182.397Z"
      fill="#505050"
    />
  </svg>
)
